<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Document Numbering</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              :headers="headers"
              :items-per-page="itemsPerPage"
              :items="numberingData"
              :search="search"
            >
              <!-- outlet template -->
              <template v-slot:item.objecttype="props">
                <v-btn
                  color="primary"
                  text
                  :to="`/document-numbering/${props.item.id}`"
                  >{{ props.item.objecttype.DocumentName }}</v-btn
                >
              </template>
              <!-- end -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    loading: false,
    itemsPerPage: 100,
    headers: [
      { text: "Document", value: "objecttype" },
      { text: "Default Series Code", value: "defaultseries.Series" },
      { text: "Default Series Name", value: "defaultseries.SeriesName" },
      { text: "First Number", value: "defaultseries.InitialNum" },
      { text: "Next Number", value: "defaultseries.NextNumber" },
      { text: "Last Number", value: "defaultseries.LastNum" },
      // { text: "External Ref.", value: "defaultseries.ExtRef" },
    ],
    numberingData: [],
  }),
  methods: {
    getData() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/documentnumbering`)
        .then((res) => {
          self.numberingData = res;
          self.loading = false;
        })
        .catch((err) => {
          console.log(err, "error");
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>
